import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { useAnalytics, useTranslate } from '~/hooks/actions'
import searchStyles from './ExpandingSearch.module.scss'

const ExpandingSearch = () => {
  const router = useRouter()
  const translate = useTranslate()

  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const showSearchInput = isHovered || isFocused

  const { handleSubmit, register, reset } = useForm()

  // Reset the form
  const resetForm = e => {
    e.preventDefault()
    reset()
  }
  // Handle Submit
  const { trackSearchViewed } = useAnalytics()
  const onSearch = async (data, e) => {
    e.preventDefault()

    trackSearchViewed({ data: data })

    router.push(`/search?q=${data.search}`)
  }

  return (
    <form
      className={searchStyles.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onSubmit={handleSubmit(onSearch)}>
      <input
        type="text"
        className={`default ${searchStyles.input} ${
          showSearchInput ? searchStyles.reveal : 'hidden'
        }`}
        placeholder={translate('search.placeholder')}
        {...register('search')}
      />

      {isFocused ? (
        <button
          type="reset"
          className={searchStyles.close}
          onClick={e => resetForm(e)}>
          <span className="icon icon--close"></span>
        </button>
      ) : (
        <button type="submit" className={searchStyles.submit}>
          {showSearchInput ? (
            <span className="icon icon--search"></span>
          ) : (
            <span>{translate('search.button')}</span>
          )}
        </button>
      )}
    </form>
  )
}
export default ExpandingSearch
