import cx from 'classnames'

import Nav from './Nav'
import { useOverlay } from '~/hooks/actions'

import headerStyles from './Header.module.scss'

const Header = ({ nav }) => {
  const { contentOverlay } = useOverlay()

  return (
    <header
      id="header"
      role="navigation"
      className={cx(headerStyles.header, {
        [headerStyles.overlayActive]: contentOverlay
      })}>
      <Nav nav={nav} />
    </header>
  )
}
export default Header
