import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import navStyles from './Nav.module.scss'
import { useOverlay, useTranslate } from '~/hooks/actions'

import useScrollDirection from '~/hooks/components/use-scroll-direction'
import ExpandingSearch from '~/components/Common/ExpandingSearch'

const Nav = ({ nav }) => {
  const router = useRouter()
  const translate = useTranslate()
  const { contentOverlay } = useOverlay()

  // Grab the device scroll direction
  const scrollDirection = useScrollDirection({
    initialDirection: null,
    thresholdPixels: 55 //This offset is the header logo that overflows out of the header nav
  })

  return (
    <section
      className={cx(navStyles.nav, {
        [navStyles.overlayActive]: contentOverlay,
        [navStyles.articlePage]: router.route === '/articles/[slug]'
      })}>
      {/* About & 404 Page */}
      {router.route === '/404' ? (
        <h1 className={navStyles.nav_title}>
          {translate('error.not_found.heading')}
        </h1>
      ) : router.route === '/about' ? (
        <h1 className={navStyles.nav_title}>About Joopiter</h1>
      ) : null}

      {/* Logo */}
      <h1
        className={cx(navStyles.nav_logo, {
          [navStyles.logohidden]:
            scrollDirection === 'scroll-down' && !contentOverlay
        })}
        aria-label="Home">
        <Link href="/">
          <a className={cx('icon', 'icon--logo', navStyles.icon)}></a>
        </Link>
      </h1>

      {/* Overlay JOOPITER logo */}
      {(contentOverlay || router.route === '/articles/[slug]') && (
        <p
          className={cx(navStyles.nav_brand, {
            [navStyles.nav_brandHidden]:
              router.route === '/articles/[slug]' &&
              !contentOverlay &&
              scrollDirection === 'scroll-down'
          })}
          aria-label="joopiter">
          <span className="icon icon--joopiter"></span>
        </p>
      )}

      {/* Right nav */}
      {nav && (
        <nav className={navStyles.nav_menu} aria-label="Nav Menu">
          <ul className={navStyles.list}>
            <li className={navStyles.list_item}>
              <ExpandingSearch />
            </li>
            {nav.map((item, index) => (
              <li className={navStyles.list_item} key={index}>
                <Link href={item.slug}>
                  <a>{translate(`header.banner.${item.name.toLowerCase()}`)}</a>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </section>
  )
}

export default Nav
